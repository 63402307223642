import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { cryptoTheme } from 'common/src/theme/crypto';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Crypto/crypto.style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';

import Banner from '../containers/Crypto/BannerSectionHELOC';
import SEO from '../components/seo';
import store from '../store';

const Navbar = loadable(() => import('../containers/Crypto/Navbar'));
const TestimonialSlider = loadable(() =>
  import('../containers/Crypto/TestimonialSlider')
);
const CompareHelocOptions = loadable(() =>
  import('../containers/Crypto/CompareHelocCashOut')
);
const Transactions = loadable(() => import('../containers/Crypto/Transaction'));
const Reviews = loadable(() => import('../containers/Crypto/ReviewPage'));
const MillionsServedHELOC = loadable(() =>
  import('../containers/Crypto/MillionsServedHELOC')
);
const Footer = loadable(() => import('../containers/Crypto/Footer2'));

const HelocMain = () => {
  return (
    <ThemeProvider theme={cryptoTheme}>
      <Provider store={store}>
        <Fragment>
          <SEO title="Best Finance - America's most flexible mortgage provider" />
          <ResetCSS />
          <GlobalStyle />
          <ContentWrapper>
            <Sticky
              top={0}
              innerZ={9999}
              inactiveClass="sticky-nav-active"
              activeClass="sticky-nav-active"
            >
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <Banner />
            <MillionsServedHELOC />
            <Transactions />
            <Reviews />
            <CompareHelocOptions />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </Provider>
    </ThemeProvider>
  );
};

export default HelocMain;
